import { useQuery } from '@tanstack/react-query';
import { realtimeDatabase } from '@util/firebase';
import { getUserById } from '@util/firestore/users';
import { useAuth } from 'context/AuthContext';
import { onValue, ref, serverTimestamp, update } from 'firebase/database';
import { useEffect, useState } from 'react';
import { formatTimestamp, timeAgo } from '..';

interface OnlineStatus {
  state: 'online' | 'offline';
  last_changed: number;
  web?: number;
  app?: number;
  web_info?: {
    ua: string;
  };
  app_info?: {
    platform: string;
    version: number;
  };
  // legacy versions
  platform?: {
    web?: number;
    app?: number;
  };
}

export const useOnlineStatus = (uid?: string) => {
  async function setOffline(uid?: string) {
    if (!uid) return;
    const statusRef = ref(realtimeDatabase, `/status/${uid}`);
    // get data from ref
    const last_changed = serverTimestamp();
    update(statusRef, {
      state: 'offline',
      web: last_changed,
      last_changed,
    });
  }

  const [status, setStatus] = useState<OnlineStatus | null>(null);

  useEffect(() => {
    // get online status
    function getOnlineStatus() {
      const statusRef = ref(realtimeDatabase, '/status/' + uid);
      return onValue(statusRef, (snapshot) => {
        const data = snapshot.val();
        setStatus(data);
      });
    }

    if (uid) {
      const unsubscribe = getOnlineStatus();
      return () => {
        unsubscribe();
      };
    }
  }, [uid]);

  return { setOffline, status, StatusLabel };
};

export const StatusLabel = ({
  status,
  hide_status,
  showLastLogin,
  recipientDocUid,
}: {
  status: OnlineStatus | null;
  hide_status?: boolean;
  showLastLogin?: boolean;
  recipientDocUid?: string;
}) => {
  const { userDoc: currUser } = useAuth();
  const { data: recipientDoc } = useQuery({
    queryKey: ['user', recipientDocUid],
    queryFn: () => getUserById(recipientDocUid!),
    enabled: !!recipientDocUid,
  });
  return !!status?.last_changed && !hide_status ? (
    <div className="mb-4 flex items-center gap-[0.6rem]">
      {status?.state === 'online' ? (
        <>
          <i
            // Tailwind not applying heights to these so I used inline styles...
            style={{
              height: '0.6rem',
              width: '0.6rem',
            }}
            className="rounded-full bg-green-500"
          ></i>
          <span className="text-[1.3rem] font-normal">
            Online
            {currUser?.roles?.support && (
              <span className="text-[1.3rem] font-normal">
                {` (${(status.web ?? 0) > (status.app ?? 0) ? 'Web' : 'App'})`}
              </span>
            )}
          </span>
        </>
      ) : (
        <div className="flex flex-col">
          <div className="flex items-center gap-[0.4rem]">
            <div
              // Tailwind not applying heights to these so I used inline styles...
              style={{
                height: '0.6rem',
                width: '0.6rem',
              }}
              className=" rounded-full bg-brand-gray"
            ></div>
            <span className="text-[1.3rem] font-normal">
              Last seen {timeAgo(status?.last_changed)}{' '}
              {currUser?.roles?.support && (
                <span className="text-[1.3rem] font-normal">
                  {` (${
                    (status.web ?? 0) > (status.app ?? 0) ? 'Web' : 'App'
                  })`}
                </span>
              )}
            </span>
          </div>

          {showLastLogin && currUser?.roles?.support && (
            <div className="flex flex-col">
              <span className="text-[1.3rem] font-normal">
                {`Last app login: (${formatTimestamp(
                  status.app ?? status.platform?.app
                )}) | App Version: ${recipientDoc?.version ?? 'N/A'} | OS: ${
                  recipientDoc?.app_data?.extinfo[0] === 'a2'
                    ? 'Android'
                    : 'iOS'
                }`}
              </span>
              <span className="text-[1.3rem] font-normal">
                {`Last web login: (${formatTimestamp(
                  status.web ?? status.platform?.web
                )})`}
              </span>
            </div>
          )}
          {currUser?.roles?.support && recipientDocUid && (
            <div className="flex flex-col">
              <span className="text-[1.3rem]">
                Name: {recipientDoc?.first} {recipientDoc?.last}
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};
